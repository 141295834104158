.f-left {
  float: left; }

.f-right {
  float: right; }

.fix {
  overflow: hidden; }

a,
.button {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

a:focus,
.button:focus {
  text-decoration: none;
  outline: none; }

a:focus,
a:hover {
  color: inherit;
  text-decoration: none; }

a,
button {
  color: inherit;
  outline: medium none;
  text-decoration: none; }

button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "DM Sans", sans-serif;
  color: #333333;
  line-height: 1.1;
  margin-top: 0px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  text-transform: normal;
  letter-spacing: -0.3px; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 40px; }

h2 {
  font-size: 35px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 22px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

  .mt-55 {
    margin-top: 55px;
}

li {
  list-style: none; }
.navbar-nav {
    padding-left: 30px;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: "DM Sans", sans-serif;
  color: #777777;
  margin-bottom: 0; }

hr {
  border-bottom: 1px solid #f1f1f1;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0; }

label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400; }

*::-moz-selection {
  background: #000;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #000;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #000;
  color: #ffffff;
  text-shadow: none; }
.dissapierning-navbar{
  display: none;
}
/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
  color: #000;
  font-size: 14px;
  opacity: 1; }

*::placeholder {
  color: #000;
  font-size: 14px;
  opacity: 1; }

/*--
    - position Classes
-----------------------------------------*/
.pos-rel {
  position: relative; }

.pos-abl {
  position: absolute; }

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden; }

.clear {
  clear: both; }

.style-text {
  font-size: 140px;
  line-height: 0.5;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #777777;
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: -19px;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .dissapierning-navbar{
      display: block;
      }
    .style-text {
      font-size: 100px; } }
  @media (max-width: 767px) {
    .dissapierning-navbar{
      display: block;
      }
    .style-text {
      font-size: 60px; } }

.overlay-bg {
  position: relative;
  z-index: 2; }
  .overlay-bg::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
