.cookie-consent-container {
    position: fixed;
    background-color: white;
    border-top: 1px solid gray;
    z-index: 9999;
    flex-direction: row;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: space-around;
    align-items: center;
}

.cookies-description-section {
    text-align: left;
    max-width: 50vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cookies-selection-section {
    font-size: 14px;

}

.cookies-x {
    align-self: flex-start;
    margin-top: 10px;
}

.cookies-x-phone {
    display: none;
}

@media only screen and (max-width: 768px) {

    .cookies-x {
        display: none;
    }

    .cookies-x-phone {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: 100%;
        margin-top: 12px;
        margin-right: 24px;
    }

    .cookie-consent-container {
        flex-direction: column;

    }

    .cookies-description-section {
        font-size: 14px;
        max-width: 70vw;
    }
}


.secondary-btn2 {
    color: #ffffff;
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 10px 45px;
    border-radius: 50px;
    text-align: center;
    text-transform: capitalize;
    background: #2ba8fb;
    font-size: large;
}

@media screen and (max-width: 767px) {
    .secondary-btn2 {
        padding: 10px 30px;
    }
}

.secondary-btn2 span {
    color: #ffffff;
    position: relative;
    z-index: 2;
    font-weight: bold;
}

.secondary-btn2::before,
.secondary-btn2::after {
    position: absolute;
    top: 50%;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #7215fa;
    border-radius: 50%;
    color: #ffffff;
}

.secondary-btn2::before {
    left: -20px;
    transform: translate(-50%, -50%);
}

.secondary-btn2::after {
    right: -20px;
    transform: translate(50%, -50%);
}

.secondary-btn2:hover {
    color: #ffffff;
}

.secondary-btn2:hover::before {
    -webkit-animation: criss-cross-left 0.8s both;
    animation: criss-cross-left 0.8s both;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.secondary-btn2:hover::after {
    -webkit-animation: criss-cross-right 0.8s both;
    animation: criss-cross-right 0.8s both;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.secondary-btn2::before,
.secondary-btn2::after {
    background-color: #7215fa;
}

.sc-primary-btn {
    margin-right: 50px;
    padding-top: 40px;
}
