/* --- Accordion General Styles --- */
.accordion {
    width: 100%;
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* --- Category Styles --- */
  .accordion-category {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 12px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .accordion-category-head {
    background: #f7f7f8;
    color: #2c3e50;
    cursor: pointer;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s ease-in-out;
  }
  
  .accordion-category-head:hover {
    background: #f0f0f1;
  }
  
  .accordion-category-head.collapsed {
    background: #f9fafb;
  }
  
  /* --- Category Body --- */
  .accordion-category-body {
    padding: 8px 16px;
    background: #ffffff;
  }
  
  /* --- Question Styles --- */
  .accordion-question {
    border-top: 1px solid #f0f0f1;
    overflow: hidden;
  }
  
  .accordion-question-head {
    background: #ffffff;
    color: #4b5563;
    cursor: pointer;
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
  }
  
  .accordion-question-head:hover {
    background: #f9fafb;
  }
  
  .accordion-question-head.collapsed {
    background: #ffffff;
  }
  
  /* --- Question Body --- */
  .accordion-question-body {
    padding: 8px 14px;
    background: #fdfdfd;
    border-top: 1px solid #f0f0f1;
    font-size: 13px;
    color: #4b5563;
    line-height: 1.5;
  }
  
  /* --- Icon Styling --- */
  .accordion-icon {
    width: 10px;
    height: 10px;
    border: solid #7b8a9a;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    transition: transform 0.2s ease-in-out;
  }
  
  .accordion-category-head.collapsed .accordion-icon,
  .accordion-question-head.collapsed .accordion-icon {
    transform: rotate(-45deg);
  }
  