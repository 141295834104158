.theme-checkbox {
    --toggle-size: 12px; /* Smaller toggle size */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 3.125em; /* Adjusted for smaller width */
    height: 1.5625em; /* Adjusted for smaller height */
    background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
    background-size: 205%;
    background-position: 0;
    transition: 0.4s;
    border-radius: 99em;
    position: relative;
    cursor: pointer;
    font-size: var(--toggle-size);
  }
  
  .theme-checkbox::before {
    content: "";
    width: 1.25em; /* Adjusted for smaller width */
    height: 1.25em; /* Adjusted for smaller height */
    position: absolute;
    top: 0.15625em; /* Adjusted for smaller top position */
    left: 0.15625em; /* Adjusted for smaller left position */
    background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
    background-size: 205%;
    background-position: 100%;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .theme-checkbox:checked::before {
    left: calc(100% - 1.25em - 0.15625em); /* Adjusted for new smaller dimensions */
    background-position: 0;
  }
  
  .theme-checkbox:checked {
    background-position: 100%;
  }
  