.blog-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
  }
  
  .blog-image-wrapper {
    position: relative;
  }
  
  .blog-image-wrapper img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .blog-category {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #007bff;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .blog-content {
    padding: 16px;
    text-align: left;
  }
  
  .blog-content h3 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .blog-date {
    font-size: 12px;
    color: #888;
  }
  
  .sidebar {
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
  }
  
  .sidebar .trial-banner {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .sidebar .latest-posts h5 {
    margin-bottom: 12px;
  }
  
  .sidebar .latest-posts ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar .latest-posts li {
    margin-bottom: 8px;
  }
  .blog-content h4.blog-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #555;
    margin-bottom: 10px;
    text-align: left;

  }
  /* Trial Banner Styling */
.trial-banner {
    background: #fffbfb;
    border-radius: 12px;
    color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .trial-heading {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .trial-description {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .trial-btn {
    background-color: #fff;
    color: #007bff;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }
  
  .trial-btn:hover {
    background-color: #007bff;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Latest Posts Styling */
  .latest-posts {
    padding: 16px;
    border-radius: 12px;
  }
  
  .latest-posts-heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  
  .latest-posts-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .latest-post-item {
    margin-bottom: 8px;
  }
  
  .latest-post-link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }
  
  .latest-post-link:hover {
    color: #0056b3;
  }
  .blog-date {
    font-size: 14px;
    color: #888;
    margin-top: 8px;
  }
  /* Trial Banner */
.trial-banner {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
  }
  
  .trial-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
  }
  
  .trial-description {
    font-size: 14px;
    color: #555;
    margin-bottom: 16px;
  }
  
  .trial-btn-outline {
    font-size: 14px;
    font-weight: 600;
    color: #007bff;
    background: transparent;
    border: 1px solid #007bff;
    padding: 8px 16px;
    border-radius: 6px;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  
  .trial-btn-outline:hover {
    background: #007bff;
    color: #fff;
    text-decoration: none;
  }
  
  /* Latest Posts */
  .latest-posts {
    padding: 16px 0;
  }
  
  .latest-posts-heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .latest-posts-list {
    list-style: none;
    padding: 0;
  }
  
  .latest-post-item {
    margin-bottom: 8px;
  }
  
  .latest-post-link {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .latest-post-link:hover {
    color: #0056b3;
  }
  