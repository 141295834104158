.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f4f8; /* Light background */
    width: 100%;
  }
  
  .success-checkmark {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    margin-bottom: 20px;
  }
  
  h1 {
    color: #333; /* Dark text for contrast */
    margin-bottom: 20px;
  }
  
  .dashboard-button {
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  