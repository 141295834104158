/* HomeBanner.module.css */
.item23 {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .item23:hover {
    transform: scale(1.05);
  }
  
  .single-services {
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .s-icon img {
    width: 60px;
    transition: transform 0.3s ease;
  }
  
  .sub-title, .text-list li {
    transition: color 0.3s ease;
  }
  
  .item:hover .sub-title, 
  .item:hover .text-list li {
    color: #007bff; /* Example color change on hover */
  }
  