/* ======================
   CSS Variables
   ====================== */

  
  /* ======================
     Base Styles
     ====================== */
  .blog-post-area {
    font-family: Arial, sans-serif;
    color: var(--text-color);
    line-height: 1.6;
    margin-top: 80px;
    padding: 2rem 0;
  }
  
  .container-blog {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    gap: 2.5rem;
    flex-wrap: wrap;
  }
  
  /* ======================
     Main Content Area
     ====================== */
  .main-content {
    flex: 1;
    min-width: 300px;
    max-width: 80%;
  }
  
  .blog-details {
    background: #fff;
    padding: 1.5rem;
    border-radius: var(--border-radius);
  }
  
  /* ======================
     Typography
     ====================== */
  .blog-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--heading-color);
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1.2;
  }
  
  .blog-subtitle {
    font-size: 1.25rem;
    color: var(--secondary-text);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .blog-content {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-top: 1.5rem;
  }
  
  /* ======================
     Markdown Content
     ====================== */
  .markdown-container {
    overflow-wrap: break-word;
    
    h1, h2, h3, h4 {
      margin: 2rem 0 1rem;
      color: var(--heading-color);
      line-height: 1.3;
    }
  
    p {
      margin-bottom: 1.5rem;
      color: var(--secondary-text);
    }
  
    ul, ol {
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
    li {
      margin-bottom: 0.5rem;
    }
  
    a {
      color: var(--primary-color);
      text-decoration: none;
      transition: var(--transition);
  
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  /* ======================
     Meta Information
     ====================== */
  .blog-meta {
    font-size: 0.9rem;
    color: var(--meta-color);
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  /* ======================
     Media Elements
     ====================== */
  .blog-cover-image img {
    width: 100%;
    height: auto;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 2rem;
  }
  
  /* ======================
     Sidebar Components
     ====================== */
  .sidebar {
    width: 320px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .recommended-articles,
  .recent-blogs {
    background: var(--background-light);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  
  .recommended-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--heading-color);
  }
  
  /* ======================
     Author Information
     ====================== */
  .writer-info {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    background: var(--background-light);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin: 2rem 0;
  
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  
    h4 {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }
  
    p {
      font-size: 0.9rem;
      color: var(--secondary-text);
    }
  }
  
  /* ======================
     Tags Component
     ====================== */
  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .tag {
    font-size: 0.9rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    background: #f0f0f0;
    color: var(--secondary-text);
  }
  
  /* ======================
     Responsive Design
     ====================== */
  @media (max-width: 1200px) {
    .container {
      gap: 2rem;
    }
  }
  
  @media (max-width: 992px) {
    .main-content {
      width: 100%;
      max-width: none;
    }
  
    .sidebar {
      width: 100%;
    }
  
    .blog-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .blog-post-area {
      padding: 1.5rem 0;
      margin-top: 60px;
    }
  
    .blog-title {
      font-size: 1.75rem;
    }
  
    .blog-subtitle {
      font-size: 1.1rem;
    }
  
    .writer-info {
      flex-direction: column;
      text-align: center;
      
      img {
        width: 100px;
        height: 100px;
      }
    }
  
    .blog-meta {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 0 1rem;
    }
  
    .blog-title {
      font-size: 1.5rem;
    }
  
    .markdown-container {
      h1, h2, h3, h4 {
        font-size: 1.25rem;
      }
      
      p {
        font-size: 1rem;
      }
    }
  }