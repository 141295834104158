.modal-wide {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .modal-wide {
    width: 95%;
    /* Adjust for smaller screens */
  }
}

.hover-color-change {
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  white-space: wrap;
  text-align: center;
  font-size: 17px;
  margin-left: 6px;
  line-height: 20px;
}

.hover-color-change:hover {
  background-color: rgba(13, 110, 253, 0.15);
}