/* Support Section Styling */
.support-section {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
  
  .support-section .block-content {
    background: #f9fafc;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    padding: 40px 30px;
    text-align: center;
    max-width: 600px;
    margin: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .support-section h4 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .support-section p {
    font-size: 1rem;
    color: #6b7280;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .support-section .btn-support {
    font-size: 1rem;
    font-weight: 500;
    border-radius: 8px;
    padding: 10px 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .support-section .btn-support:hover {
    background-color: #3b82f6;
    transform: translateY(-2px);
  }
  