body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.newButtonLink {
  padding: 12.5px 30px;
  border: 0;
  border-radius: 30px;
  background-color: #2ba8fb;
  color: #ffffff;
  font-weight: Bold;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.newButtonLink:hover {
  background-color: #6fc5ff;
  box-shadow: 0 0 20px #6fc5ff50;
  transform: scale(1.1);
}

.newButtonLink:active {
  background-color: #3d94cf;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
  transform: scale(0.98);
}
/* custom.css */
.bottone5 {
  align-items: center;
  background-color: #ffffff;
  border: 2px solid rgba(48, 155, 255, 0.8);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  margin-bottom: 30px;
  padding: 9px;
}
 
 .bottone5:hover,
 .bottone5:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
 }
 
.bottone5:hover {
  transform: translateY(-1px);
 }
 
.bottone5:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
 }
/* For modern browsers */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* For Firefox */


/* For IE and Edge */
body {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}



.container {
  max-width: 91% !important;
}

@media (max-width: 1200px) {
  .container {
    max-width: 95% !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 3s ease-out forwards;
}


.pricing-cards .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pack-container-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #f0f4f8;
  border: 1px solid #007bff;
  padding: 1.6rem;
  color: #000;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: transform 0.3s ease;
}

.pack-container-custom:hover {
  transform: scale(1.05);
}

.header-custom {
  position: relative;
  margin: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 0;
  border-bottom: 1px solid #007bff;
  background: transparent;
  padding-bottom: 1rem;
  text-align: center;
}

.title-custom {
  display: block;
  font-family: sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  color: #007bff;
  font-weight: bold;
}

.most-popular {
  background-color: #000000;
  color: white;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 0.75rem;
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
}

.price-container-custom {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  font-family: sans-serif;
  font-size: 2.7rem;
  line-height: 1;
}

.price-container-custom span:first-child {
  margin-top: 10px;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.price-container-custom span:last-child {
  align-self: flex-end;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.yearly-price {
  font-size: 1rem;
  color: #007bff;
}

.lists-custom {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list-custom {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-custom span {
  border-radius: 50%;
  border: 1px solid rgba(0, 123, 255, 0.24);
  background-color: rgba(0, 123, 255, 0.185);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-custom span svg {
  height: 12px;
  width: 12px;
}

.list-custom p {
  display: block;
  font-family: sans-serif;
}

.button-container-custom {
  margin-top: 20px;
  padding: 0;
}

.button-container-custom button {
  display: block;
  width: 100%;
  background-color: #007bff;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  outline: 0;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}

.button-container-custom button:hover {
  background-color: #0056b3;
}

.yearly {
  animation: yearlyAnimation 0.6s ease forwards;
}

.monthly {
  animation: monthlyAnimation 0.6s ease forwards;
}

@keyframes yearlyAnimation {
  from { transform: rotateY(90deg); opacity: 0; }
  to { transform: rotateY(0); opacity: 1; }
}

@keyframes monthlyAnimation {
  from { transform: rotateY(-90deg); opacity: 0; }
  to { transform: rotateY(0); opacity: 1; }
}
/* The switch - the box around the slider */
.switch123 {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 62px;
  height: 35px;
 }
 
 /* Hide default HTML checkbox */
 .switch123 input {
  opacity: 1;
  width: 0;
  height: 0;
 }
 
 /* The slider */
 .slider123 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background: #fff;
  transition: .4s;
  border-radius: 30px;
  border: 1px solid #ccc;
 }
 
 .slider123:before {
  position: absolute;
  content: "";
  height: 1.9em;
  width: 1.9em;
  border-radius: 16px;
  left: 1.2px;
  top: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0 2px 5px #999999;
  transition: .4s;
 }
 
 .input123:checked + .slider123 {
  background-color: #5fdd54;
  border: 1px solid transparent;
 }
 
 .input123:checked + .slider123:before {
  transform: translateX(1.5em);
 }

 .prominent-button {
  margin-top: 20px;
  width: 100%;
  font-size: 1.2em;
  background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: none;
  transition: background-color 0.3s, transform 0.2s;
}

.prominent-button:hover,
.prominent-button:focus {
  background-color: darkblue;
  transform: scale(1.02);
  text-decoration: none;
}

.prominent-button:active {
  transform: scale(0.98);
}

.prominent-button-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.prominent-button-text {
  font-family: sans-serif;
  color: #fff;
}
