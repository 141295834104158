.buy-button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    border-radius: 5px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .buy-button:hover {
    background-color: #45a049; /* Darker shade for the hover state */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow for depth */
    cursor: pointer;
  }
  