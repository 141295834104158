.cta-banner {
    background: #f9f9f9;
    padding: 1.5rem 1rem;
    border-radius: 0.625rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    text-align: center;
  }
  
  .cta-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #fff;
    background: #007bff;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
  
    &:hover {
      background: #0056b3;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
  }