.account-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .account-actions .support-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }
  
  .account-actions button {
    width: auto; /* Adjust based on your design */
  }
  .user-details-card {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .account-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .account-actions button {
    flex: 1 1 calc(33.333% - 10px);
    min-width: 200px;
  }
  
  .support-section {
    background: #f9fafc;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .support-section p {
    font-size: 0.9rem;
    color: #6b7280;
  }
  /* --- General Button Styles --- */
.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 0.95rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
}

/* --- Colors --- */
.custom-button-primary {
  background-color: #4f46e5;
  color: #ffffff;
}

.custom-button-primary:hover {
  background-color: #4338ca;
  transform: translateY(-2px);
}

.custom-button-danger {
  background-color: #e11d48;
  color: #ffffff;
}

.custom-button-danger:hover {
  background-color: #be123c;
  transform: translateY(-2px);
}

.custom-button-info {
  background-color: #3b82f6;
  color: #ffffff;
}

.custom-button-info:hover {
  background-color: #2563eb;
  transform: translateY(-2px);
}

/* --- Outline Variants --- */
.custom-button-outline {
  background: transparent;
  border: 2px solid currentColor;
}

.custom-button-outline.custom-button-primary {
  color: #4f46e5;
  border-color: #4f46e5;
}

.custom-button-outline.custom-button-primary:hover {
  background-color: #4f46e5;
  color: #ffffff;
}

.custom-button-outline.custom-button-info {
  color: #3b82f6;
  border-color: #3b82f6;
}

.custom-button-outline.custom-button-info:hover {
  background-color: #3b82f6;
  color: #ffffff;
}

/* --- Disabled State --- */
.custom-button-disabled {
  background-color: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
  pointer-events: none;
  border: none;
}
