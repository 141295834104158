.recommended-articles {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    color:black
  }
  
  .recommended-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .recommended-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .recommended-item {
    margin-bottom: 8px;
  }
  
  .recommended-link {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
  }
  
  .recommended-link:hover {
    text-decoration: underline;
  }
  