.purchase-history {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 12px;
    margin: 20px auto;
    max-width: 800px;
  }
  
  .purchase-history .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .purchase-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .purchase-table th,
  .purchase-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .purchase-table th {
    background-color: #f0f4f8;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
  }
  
  .purchase-table tr:nth-child(even) {
    background-color: #f9fafc;
  }
  
  .purchase-table tr:hover {
    background-color: #eef2f7;
  }
  
  .purchase-table td {
    font-size: 0.9rem;
    color: #444;
    border-bottom: 1px solid #e6e9ec;
  }
  
  .no-purchases {
    font-size: 1rem;
    color: #777;
    margin-top: 10px;
  }
  
  .purchase-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .purchase-button:hover {
    background-color: #2e8b57;
  }
  
  .button-icon {
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .purchase-history {
      padding: 20px;
    }
  
    .purchase-table th,
    .purchase-table td {
      font-size: 0.8rem;
      padding: 8px 10px;
    }
  
    .purchase-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  