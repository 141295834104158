.campaign-table-container {
    overflow-x: auto;
  }
  
  .table-tranx1 {
    width: 100%;
    min-width: 600px; /* Adjust as needed */
  }
  
  @media only screen and (max-width: 768px) {
    .table-tranx1 th, .table-tranx1 td {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }
  