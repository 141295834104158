.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .comparison-table th, .comparison-table td {
    padding: 12px 15px;
    border: 1px solid #eee;
    text-align: center;
  }
  
  .comparison-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .comparison-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .comparison-table th[scope="row"] {
    background-color: #f9f9f9;
    font-weight: bold;
  }
  
  .comparison-table button {
    padding: 8px 15px;
    background-color: #2ba8fb;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .comparison-table button:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }
  
  .comparison-table button:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }
  
  .comparison-container {
    max-width: 91% !important;
    margin: auto;
  }
  
  @media (max-width: 1200px) {
    .comparison-container {
      max-width: 95% !important;
    }
  }
  
  @media (max-width: 768px) {
    .comparison-container {
      max-width: 100% !important;
    }
  }
  
  .header-custom122 {
    margin: 0;
    margin-bottom: 1rem;
    overflow: hidden;
    background: transparent;
    padding-bottom: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: #007bff;
    font-family: "DM Sans", sans-serif; 
  }
  
  .price-container122 {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    font-size: 1.2rem;
    line-height: 1;
  }
  
  .price-container122 span:first-child {
    margin-top: 5px;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  
  .price-container122 span:last-child {
    align-self: flex-end;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  
  .button-container122 {
    margin-top: 10px;
  }
  