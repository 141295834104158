.qr-section {
    text-align: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #f9fafc;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .qr-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .qr-code-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  
  .qr-text {
    font-size: 0.9rem;
    color: #666;
    margin-top: 10px;
  }
  
  .qr-link {
    color: #2e8b57;
    text-decoration: underline;
    font-size: 0.9rem;
    margin-top: 5px;
  }
  
  .qr-link:hover {
    color: #1c5c3e;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .qr-code-container {
      margin: 20px 0;
    }
  
    .qr-section {
      padding: 15px;
    }
  
    .qr-title {
      font-size: 1rem;
    }
  
    .qr-text {
      font-size: 0.8rem;
    }
  
    .qr-link {
      font-size: 0.8rem;
    }
  }
  